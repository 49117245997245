import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiaHJ2eXgiLCJhIjoiY2x0OWJjM2RrMTYyazJpbzd2N3kzbjdpbCJ9.EH2_nn9bX5Dp5gjoitStCA';

const SMOOTHING_FACTOR = 0.2;
const MAX_DISTANCE_KM = 1;

const mapStyles = {
  Dawn: 'mapbox://styles/hrvyx/cm0fzkrhf00yk01pwedzq6fu8',
  Day: 'mapbox://styles/hrvyx/cm0fzi7o9000n01pw1we0dooe',
  Dusk: 'mapbox://styles/hrvyx/cm0fzjhy5010j01rbbgor55y9',
  Night: 'mapbox://styles/hrvyx/cm0btrcty00sd01pn8l2e6idl'
};

const App = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoom, setZoom] = useState(2);
  const [status, setStatus] = useState('Disconnected');
  const [debugInfo, setDebugInfo] = useState([]);
  const trackCoordinates = useRef([]);
  const smoothedPosition = useRef({ lon: 0, lat: 0, alt: 0 });
  const isInitialized = useRef(false);
  const [isCapturing, setIsCapturing] = useState(false);
  const [timer, setTimer] = useState(0);
  const intervalRef = useRef(null);
  const debugBoxRef = useRef(null);
  const [currentMapStyle, setCurrentMapStyle] = useState(mapStyles.Day);
  const recordedCoordinates = useRef([]);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: currentMapStyle,
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.on('load', function() {
      map.current.addSource('track', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: []
          }
        }
      });

      map.current.addLayer({
        id: 'track-line',
        type: 'line',
        source: 'track',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#FF69B4',
          'line-width': 2
        }
      });

      map.current.addControl(new mapboxgl.NavigationControl());
    });

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(6));
      setLat(map.current.getCenter().lat.toFixed(6));
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, [currentMapStyle]);

  const changeMapStyle = (styleName) => {
    const newStyle = mapStyles[styleName];
    if (map.current && newStyle) {
      map.current.setStyle(newStyle);
      setCurrentMapStyle(newStyle);

      map.current.once('style.load', () => {
        map.current.addSource('track', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: trackCoordinates.current
            }
          }
        });

        map.current.addLayer({
          id: 'track-line',
          type: 'line',
          source: 'track',
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#FF69B4',
            'line-width': 2
          }
        });
      });
    }
  };

  useEffect(() => {
    if (isCapturing) {
      intervalRef.current = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
      setTimer(0);
    }

    return () => clearInterval(intervalRef.current);
  }, [isCapturing]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const toggleCapture = () => {
    if (isCapturing) {
      exportCoordinates();
    } else {
      recordedCoordinates.current = [];
      console.log('Started new recording');
    }
    setIsCapturing(!isCapturing);
  };

  const exportCoordinates = () => {
    console.log('Recorded coordinates:', recordedCoordinates.current);

    if (recordedCoordinates.current.length === 0) {
      alert('No coordinates recorded.');
      return;
    }

    const csvContent = 'data:text/csv;charset=utf-8,Lat,Lon,Alt\n' +
      recordedCoordinates.current.map(coord => `${coord.lat},${coord.lon},${coord.alt}`).join('\n');

    console.log('CSV content:', csvContent);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'recorded_coordinates.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setDebugInfo(prevDebug => [...prevDebug, `Exported ${recordedCoordinates.current.length} recorded coordinates`]);
  };

  const haversineDistance = (lon1, lat1, lon2, lat2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
              Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
  };

  const smoothPosition = (newPos) => {
    smoothedPosition.current = {
      lon: smoothedPosition.current.lon + SMOOTHING_FACTOR * (newPos.lon - smoothedPosition.current.lon),
      lat: smoothedPosition.current.lat + SMOOTHING_FACTOR * (newPos.lat - smoothedPosition.current.lat),
      alt: newPos.alt
    };
    return [smoothedPosition.current.lon, smoothedPosition.current.lat];
  };

  const updateTrack = (newCoord) => {
    if (!Array.isArray(newCoord) || newCoord.length !== 3 || newCoord.some(isNaN)) {
      setDebugInfo(prevDebug => [...prevDebug, `Invalid coordinate received: ${newCoord}`]);
      return;
    }

    if (!isInitialized.current) {
      isInitialized.current = true;
      smoothedPosition.current = { lon: newCoord[0], lat: newCoord[1], alt: newCoord[2] };
      trackCoordinates.current = [[newCoord[0], newCoord[1]]];
      if (map.current) {
        map.current.flyTo({
          center: [newCoord[0], newCoord[1]],
          zoom: 17,
          duration: 0
        });
      }
      setDebugInfo(prevDebug => [...prevDebug, `Initialized at: ${newCoord.join(', ')}`]);
    } else {
      const lastCoord = trackCoordinates.current[trackCoordinates.current.length - 1];
      const distance = haversineDistance(lastCoord[0], lastCoord[1], newCoord[0], newCoord[1]);
      if (distance > MAX_DISTANCE_KM) {
        setDebugInfo(prevDebug => [...prevDebug, `Skipped point due to large distance: ${distance.toFixed(2)} km`]);
        return;
      }
      const smoothedCoord = smoothPosition({ lon: newCoord[0], lat: newCoord[1], alt: newCoord[2] });
      trackCoordinates.current.push(smoothedCoord);
    }

    if (map.current) {
      const source = map.current.getSource('track');
      if (source) {
        source.setData({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: trackCoordinates.current
          }
        });

        map.current.easeTo({
          center: [newCoord[0], newCoord[1]],
          duration: 1000
        });
      }
    }

    const altFormatted = typeof newCoord[2] === 'number' ? newCoord[2].toFixed(2) : newCoord[2];
    const debugEntry = `Lat: ${newCoord[1].toFixed(6)}, Lon: ${newCoord[0].toFixed(6)}, Alt: ${altFormatted}`;
    setDebugInfo(prevDebug => [...prevDebug, debugEntry].slice(-100));

    if (isCapturing) {
      recordedCoordinates.current.push({
        lat: newCoord[1].toFixed(6),
        lon: newCoord[0].toFixed(6),
        alt: altFormatted
      });
      console.log('Added coordinate:', recordedCoordinates.current[recordedCoordinates.current.length - 1]);
    }

    setLng(newCoord[0].toFixed(6));
    setLat(newCoord[1].toFixed(6));
  };

  useEffect(() => {
    let socket;

    const connectWebSocket = () => {
      socket = new WebSocket('ws://100.72.91.29:8756');

      socket.onopen = () => {
        console.log('WebSocket Connected');
        setStatus('Connected');
        setDebugInfo(prevDebug => [...prevDebug, 'WebSocket connected']);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        updateTrack([data.lon, data.lat, data.alt]);
      };

      socket.onclose = () => {
        console.log('WebSocket Disconnected');
        setStatus('Disconnected');
        setDebugInfo(prevDebug => [...prevDebug, 'WebSocket disconnected']);
        setTimeout(connectWebSocket, 5000);
      };

      socket.onerror = (error) => {
        console.error('WebSocket Error:', error);
        setDebugInfo(prevDebug => [...prevDebug, `WebSocket error: ${error.message}`]);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  useEffect(() => {
    if (debugBoxRef.current) {
      debugBoxRef.current.scrollTop = debugBoxRef.current.scrollHeight;
    }
  }, [debugInfo]);

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
      <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />

      <div style={{ position: 'absolute', top: 10, left: 10, background: 'rgba(255,255,255,0.7)', padding: 10, borderRadius: 5 }}>
        {Object.keys(mapStyles).map((styleName) => (
          <button
            key={styleName}
            onClick={() => changeMapStyle(styleName)}
            style={{
              marginRight: '5px',
              background: currentMapStyle === mapStyles[styleName] ? '#8C008C' : 'white',
              color: currentMapStyle === mapStyles[styleName] ? 'white' : 'black',
              border: '1px solid #ccc',
              padding: '5px 10px',
              cursor: 'pointer'
            }}
          >
            {styleName}
          </button>
        ))}
      </div>

      <div style={{ position: 'absolute', top: 70, left: 10, background: 'rgba(255,255,255,0.7)', padding: 10, borderRadius: 5 }}>
        <div>Status: {status}</div>
        <div>Zoom Level: {zoom}</div>
        <div>Lat: {lat}</div>
        <div>Lon: {lng}</div>
      </div>

      <div style={{ 
        position: 'absolute', 
        bottom: 10, 
        left: 10, 
        background: 'rgba(255,255,255,0.7)', 
        padding: 10, 
        borderRadius: 5, 
        width: '550px',
        height: '200px',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <button 
          onClick={toggleCapture} 
          style={{ 
            marginBottom: '10px', 
            background: isCapturing ? '#ff4136' : '#2ecc40', 
            color: 'white',
            border: 'none', 
            padding: '10px', 
            fontSize: '1em', 
            cursor: 'pointer',
            borderRadius: '5px'
          }}
        >
          {isCapturing ? `Stop Recording (${formatTime(timer)})` : 'Start Recording'}
        </button>
        <div 
          ref={debugBoxRef}
          style={{
            flexGrow: 1,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '0.8em'
          }}
        >
          {debugInfo.map((info, index) => (
            <div key={index} style={{
              padding: '1.5px 0'
            }}>
              {info}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;